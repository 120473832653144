import * as React from 'react';
import {Paper} from '@mui/material';

const Sheet = ({
  children = null,
  sx = {},
  elevation = 2,
  ...props
}) => {
  return <Paper sx={{
    mt: 2,
    p: 3,
    ...sx
  }} elevation={elevation} {...props}>
    {children}
  </Paper>;
};
export default React.memo(Sheet);
