import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, LinearProgress, Stack, Typography, useTheme} from '@mui/material';
import axios from 'axios';

import {useAxiosWithoutCache} from '../index';

import Sheet from './Sheet';

const DisplayXHM = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [{
    data: info,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/display?game=XHM');
  const [bots, setBots] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    setStep(0);
    setBots(null);
    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(async (id) => (await axios.get(`/api/bot/${id}`)).data));
        setBots(bots);
      })();
    }
  }, [info]);

  useEffect(() => {
    let timeout;
    if (!info || !bots) {
      console.log('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => reload(), 5000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 2500);
      }
    }
    return () => clearTimeout(timeout);
  }, [step, info, bots, reload]);

  const state = info?.data?.states?.[step - 1];

  return <Sheet id='display' sx={{p: 0}} onDoubleClick={() => {
    const player = document.getElementById('display');
    if (player && document.fullscreenEnabled && player.requestFullscreen) {
      if (!document.fullscreenElement) {
        player.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }}>
    {!info || !bots || !state
      ? <LinearProgress/>
      : <>
        <Stack direction='column' justifyContent='center' sx={{height: 'calc(100% - min(1.5vw,2.25vh))'}}>
          <Stack direction='row' justifyContent='space-evenly'>
            <Box sx={{
              width: 'min(40vw,60vh)'
            }}>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>{bots[0].name}</Typography>
              <Typography variant='body2' color={theme.palette.primary.main}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(2vw,3vh)',
                  lineHeight: 'min(4vw,6vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>Team "{bots[0].team}"</Typography>
            </Box>
          </Stack>
          <Box sx={{margin: 'min(6vw,9vh) 0', textAlign: 'center'}}>
            {step !== info.data.states.length && <>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.25
                }}>_</Typography>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.5
                }}>_</Typography>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.75
                }}>_</Typography>
            </>}
            {state.word.replace(/^_+|_+$/g, '').split('')
              .map((letter, i) => <Typography key={i} variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: state.guessed.includes(letter) ? '#27ae60' : (letter === '_' ? theme.palette.text.secondary : '#e74c3c'),
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block'
                }}>{letter}</Typography>)}
            {step !== info.data.states.length && <>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.75
                }}>_</Typography>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.5
                }}>_</Typography>
              <Typography variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: theme.palette.text.secondary,
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  opacity: 0.25
                }}>_</Typography>
            </>}
          </Box>
          <Box sx={{textAlign: 'center'}}>
            {state.guessed
              .filter((letter) => !state.word.includes(letter))
              .map((letter, i) => <Typography key={i} variant='h4' color={theme.palette.primary.main}
                sx={{
                  color: '#e74c3c',
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block'
                }}>{letter}</Typography>)}
          </Box>
        </Stack>
        <LinearProgress value={100 / info.data.states.length * step} variant='determinate'
          sx={{
            m: 'min(0.5vw,0.75vh)',
            height: 'min(0.5vw,0.75vh)',
            borderRadius: 'min(0.25vw,0.375vh)'
          }}/>
      </>}
  </Sheet>;
};
export default React.memo(DisplayXHM);
