import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Tooltip} from '@mui/material';
import md5 from 'md5';

const UserAvatar = ({profile, tooltip = false}) => {
  const {t} = useTranslation();
  const avatar = <Avatar src={profile.photo ?? `https://vanillicon.com/v2/${md5(profile.id)}.svg`} alt={profile.username} sx={{
    bgcolor: 'background.default'
  }}/>;
  if (tooltip) {
    return <Tooltip title={profile.username} arrow>
      {avatar}
    </Tooltip>;
  }
  return avatar;
};
export default React.memo(UserAvatar);
