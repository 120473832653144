import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Box, Container, createTheme, CssBaseline, GlobalStyles, ThemeProvider} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/lab';
import {deDE as muiDE, enUS as muiEN} from '@mui/material/locale';
import {de as dfnDE, enUS as dfnEN} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';
import {ThemeOptions} from '@mui/material/styles/createTheme';
import {PaletteColor} from '@mui/material/styles/createPalette';
import {SnackbarProvider} from 'notistack';

import {useAxiosWithoutCache} from '../index';
import Profile from '../../interfaces/Profile';

import Start from './Start';
import Unknown from './Unknown';
import Header from './Header';
import UserRanking from './UserRanking';
import TeamRanking from './TeamRanking';
import ProfileTeams from './ProfileTeams';
import ProfileTeam from './ProfileTeam';
import ProfileTeamNew from './ProfileTeamNew';
import Footer from './Footer';
import Game from './Game';
import Competition from './Competition';
import ProfileCompetitions from './ProfileCompetitions';
import ProfileCompetition from './ProfileCompetition';
import Team from './Team';
import BotRanking from './BotRanking';
import DisplaySV from './DisplaySV';
import DisplayMTTT from './DisplayMTTT';
import DisplayHM from './DisplayHM';
import DisplayXHM from './DisplayXHM';

console.log('muiLocales', muiDE, muiEN);
console.log('dfnLocales', dfnDE, dfnEN);

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {
  }
});
export const ProfileContext = React.createContext<{
  profile: Profile;
  profileLoading: boolean;
  profileError: any;
  profileReload: any;
}>(null);

const getBaseTheme = (): 'light' | 'dark' => {
  // const theme = window.localStorage.getItem('theme');
  // return theme === 'light' ? 'light' : 'dark';
  return 'light';
};

const globalStyles = <GlobalStyles styles={{
  'html, body, #root': {
    height: '100%'
  }
}}/>;

declare module '@mui/material/styles' {
  interface Palette {
    facebook?: PaletteColor;
    google?: PaletteColor;
    reddit?: PaletteColor;
    discord?: PaletteColor;
    battlenet?: PaletteColor;
    github?: PaletteColor;
    spotify?: PaletteColor;
  }

  interface PaletteOptions {
    facebook?: PaletteColor;
    google?: PaletteColor;
    reddit?: PaletteColor;
    discord?: PaletteColor;
    battlenet?: PaletteColor;
    github?: PaletteColor;
    spotify?: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    facebook: true;
    google: true;
    reddit: true;
    discord: true;
    battlenet: true;
    github: true;
    spotify: true;
  }
}

const App = () => {
  const {i18n} = useTranslation();
  const [mode, setMode] = React.useState<'light' | 'dark'>(getBaseTheme());
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const mode = prevMode === 'light' ? 'dark' : 'light';
          window.localStorage.setItem('theme', mode);
          return mode;
        });
      }
    }),
    []
  );
  const [{
    data: profile,
    loading: profileLoading,
    error: profileError
  }, profileReload] = useAxiosWithoutCache<Profile>('/api/profile');
  const {palette} = createTheme();
  const theme: ThemeOptions = {
    palette: {
      mode,
      primary: {main: '#181818'},
      success: {main: '#27ae60'},
      error: {main: '#c0392b'},
      warning: {main: '#d35400'},
      info: {main: '#2980b9'},
      facebook: palette.augmentColor({
        color: {
          main: '#1877f2',
          contrastText: '#fff'
        }
      }),
      google: palette.augmentColor({
        color: {
          main: '#ea4335',
          contrastText: '#fff'
        }
      }),
      reddit: palette.augmentColor({
        color: {
          main: '#ff4500',
          contrastText: '#fff'
        }
      }),
      discord: palette.augmentColor({
        color: {
          main: '#5865f2',
          contrastText: '#fff'
        }
      }),
      battlenet: palette.augmentColor({
        color: {
          main: '#0566b0',
          contrastText: '#fff'
        }
      }),
      github: palette.augmentColor({
        color: {
          main: '#24292f',
          contrastText: '#fff'
        }
      }),
      spotify: palette.augmentColor({
        color: {
          main: '#1db954',
          contrastText: '#fff'
        }
      })
    }
  };
  if (mode === 'light') {
    theme.palette.background = {default: '#efefef'};
  }
  let muiLocale, dfnLocale;
  switch (i18n.language) {
    case 'de':
      muiLocale = muiDE;
      dfnLocale = dfnDE;
      break;
    case 'en':
    default:
      muiLocale = muiEN;
      dfnLocale = dfnEN;
      break;
  }
  return <ColorModeContext.Provider value={colorMode}>
    <ProfileContext.Provider value={{
      profile,
      profileLoading,
      profileError,
      profileReload
    }}>
      <ThemeProvider theme={createTheme(theme, muiLocale)}>
        <LocalizationProvider locale={dfnLocale} dateAdapter={DateAdapter}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
            <CssBaseline/>
            {globalStyles}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}>
              <Header/>
              <Container sx={{flexGrow: 1}}>
                <Routes>
                  <Route path='/display/SV' element={<DisplaySV/>}/>
                  <Route path='/display/MTTT' element={<DisplayMTTT/>}/>
                  <Route path='/display/HM' element={<DisplayHM/>}/>
                  <Route path='/display/XHM' element={<DisplayXHM/>}/>
                  <Route path='/' element={<Start/>}/>
                  {/* <Route path='/games' element={<Games/>}/> */}
                  <Route path='/game/:gameId' element={<Game/>}/>
                  {/* <Route path='/competitions' element={<Competitions/>}/> */}
                  <Route path='/competition/:competitionId' element={<Competition/>}/>
                  {/* <Route path='/teams' element={<Teams/>}/> */}
                  <Route path='/team/:teamId' element={<Team/>}/>
                  <Route path='/ranking/users' element={<UserRanking/>}/>
                  <Route path='/ranking/teams' element={<TeamRanking/>}/>
                  <Route path='/ranking/bots' element={<BotRanking/>}/>
                  <Route path='/profile/teams' element={<ProfileTeams/>}/>
                  <Route path='/profile/team/new' element={<ProfileTeamNew/>}/>
                  <Route path='/profile/team/:teamId' element={<ProfileTeam/>}/>
                  <Route path='/profile/competitions' element={<ProfileCompetitions/>}/>
                  <Route path='/profile/competition/:competitionId' element={<ProfileCompetition/>}/>
                  <Route path='*' element={<Unknown/>}/>
                </Routes>
              </Container>
              <Footer/>
            </Box>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ProfileContext.Provider>
  </ColorModeContext.Provider>;
};
export default React.memo(App);
