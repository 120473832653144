import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Card, CardActionArea, CardContent, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useSnackbar} from 'notistack';
import {Link as RouterLink} from 'react-router-dom';

import {useAxiosWithoutCache} from '../index';

const Games = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [{
    data: games,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/game');
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return <Box display='grid' gridTemplateColumns='repeat( auto-fill, minmax(320px, 1fr) )' gap={2}>
    {games?.map((game) => <Card key={game.id}>
      <CardActionArea component={RouterLink} to={`/game/${game.id}`} sx={{height: '100%'}}>
        <CardContent sx={{p: 3}}>
          <Typography variant='h5' component='div'>
            {game.name}
          </Typography>
          <Typography gutterBottom variant='subtitle1' component='div'>
            {game.subtitle}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {game.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>)}
  </Box>;
};
export default React.memo(Games);
