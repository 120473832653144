import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import {Link as RouterLink} from 'react-router-dom';
import {
  AppBar,
  Badge,
  Box,
  Container,
  Dialog,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';

import {ProfileContext} from './App';
import UserAvatar from './UserAvatar';
import Profile from './Profile';

const Header = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {
    profile,
    profileLoading
  } = useContext(ProfileContext);
  const [showProfile, setShowProfile] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages: { to: string, title: string, loginRequired?: boolean, badge?: number}[] = [
    {title: 'Start', to: '/'},
    {title: 'Team-Rangliste', to: '/ranking/teams'},
    {title: 'Spieler-Rangliste', to: '/ranking/users'},
    {title: 'Bot-Rangliste', to: '/ranking/bots'},
    {title: 'meine Teams', to: '/profile/teams', loginRequired: true, badge: profile?.teamrequests},
    {title: 'meine Turniere', to: '/profile/competitions', loginRequired: true, badge: profile?.competitionrequests, competitionmanagerRequired: true}
  ].filter((e) => !e.loginRequired || !!profile)
    .filter((e) => !e.competitionmanagerRequired || profile?.competitionmanager);

  let userInfo = null;
  if (!profileLoading) {
    if (profile) {
      userInfo = <Box sx={{flexGrow: 0}}>
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Badge color='info' badgeContent={
            (profile.invites?.length ?? 0) + (profile.competitioninvites?.length ?? 0)
          }>
            <UserAvatar profile={profile}/>
          </Badge>
        </IconButton>
        <Menu
          sx={{mt: '45px'}}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={() => {
            handleCloseUserMenu();
            setShowProfile(true);
          }}>
            <Typography textAlign='center'>Profil</Typography>
          </MenuItem>
          <MenuItem component='a' href='/auth/logout'>
            <Typography textAlign='center'>abmelden</Typography>
          </MenuItem>
        </Menu>
      </Box>;
    } else {
      userInfo = <Link underline='none' variant='inherit' color='inherit' href='#' onClick={() => {
        setShowProfile(true);
      }}>anmelden</Link>;
    }
  }

  return <Paper sx={{background: 'white', pt: 5}} square elevation={4}>
    <Container>
      <img style={{display: 'block', height: '200px'}} src='/images/logo.png'/>
    </Container>
    <AppBar position='relative'>
      <Container>
        <Toolbar disableGutters>
          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size='large'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'}
              }}
            >
              {pages.map(({title, to, badge}) => (
                <MenuItem key={to} component={RouterLink} to={to} onClick={handleCloseNavMenu}>
                  <Badge color='info' badgeContent={badge}>
                    <Typography textAlign='center'>{title}</Typography>
                  </Badge>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, gridGap: theme.spacing(4)}}>
            {pages.map(({title, to, badge}) => (
              <Link key={to} component={RouterLink} to={to} underline='none' variant='inherit' color='inherit'>
                <Badge color='info' badgeContent={badge}>
                  {title}
                </Badge>
              </Link>
            ))}
          </Box>
          {userInfo}
        </Toolbar>
      </Container>
    </AppBar>

    <Dialog
      open={showProfile}
      onClose={() => setShowProfile(false)}
      scroll='body'
      maxWidth='xs'
      fullWidth
    >
      <Profile onClose={() => setShowProfile(false)}/>
    </Dialog>
  </Paper>;
};
export default React.memo(Header);
