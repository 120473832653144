import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';

import {handleAsyncError} from '../util/handleAsyncError';
import {useAxiosWithoutCache} from '../index';

import {ProfileContext} from './App';
import Sheet from './Sheet';

const ProfileTeamNew = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {profile, profileReload} = useContext(ProfileContext);

  const [type, setType] = useState('PUBLIC');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [competition, setCompetition] = React.useState(null);
  const [{
    data: competitions
  }] = useAxiosWithoutCache('/api/profile/competition/selection');

  if (!profile) {
    return null;
  }
  return <>
    <Sheet>
      <Stack spacing={2}>
        <Typography variant='h4' gutterBottom sx={{flexGrow: 1, flexShrink: 1}}>neues Team anlegen</Typography>
        <TextField size='small' type='text' label='Name'
          value={name} onChange={(event) => {
            setName(event.target.value);
          }}/>
        <FormControl size='small' fullWidth>
          <InputLabel id='competition-select-label'>Turnier</InputLabel>
          <Select labelId='competition-select-label'
            value={competition}
            onChange={(event) => {
              setCompetition(event.target.value);
            }}>
            <MenuItem value={null}>
              kein Turnier
            </MenuItem>
            {(competitions ?? []).map((competition) => {
              return <MenuItem key={competition?.id} value={competition?.id}>
                {competition?.name}
              </MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Einstellung für neue Mitglieder</FormLabel>
          <RadioGroup
            value={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
          >
            <FormControlLabel value='PRIVATE' control={<Radio/>} label='Beitrittsanfragen nicht erlauben'/>
            <FormControlLabel value='PUBLIC' control={<Radio/>} label='Beitrittsanfragen erlauben'/>
            <FormControlLabel value='AUTOACCEPT' control={<Radio/>} label='Jeder darf beitreten'/>
          </RadioGroup>
        </FormControl>
        <TextField size='small' type='text' label='Beschreibung' multiline rows={5}
          value={description} onChange={(event) => {
            setDescription(event.target.value);
          }}/>
        <Box sx={{
          display: 'flex',
          gridAutoFlow: 'column',
          gridGap: theme.spacing(2)
        }}>
          <Box sx={{flexGrow: 1, flexShrink: 1}}/>
          <Button variant='contained' disabled={!name} onClick={async () => {
            const team = await handleAsyncError(axios.post('/api/profile/team', {
              competition,
              type,
              name,
              description
            }), enqueueSnackbar);
            if (team?.id) {
              navigate(`/profile/team/${team.id}`);
            }
          }}>speichern</Button>
        </Box>
      </Stack>
    </Sheet>
  </>;
};
export default React.memo(ProfileTeamNew);
