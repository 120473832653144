import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import {
  Autocomplete,
  Box,
  Button, Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';

import {handleAsyncError} from '../util/handleAsyncError';
import {useAxiosWithoutCache} from '..';

import {ProfileContext} from './App';
import UserAvatar from './UserAvatar';
import Sheet from './Sheet';
import Unknown from './Unknown';

const ProfileTeam = (props) => {
  const {teamId} = useParams();
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {profile, profileReload} = useContext(ProfileContext);
  const [{
    data: team,
    error
  }, reload] = useAxiosWithoutCache(`/api/profile/team/${teamId}`);
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [userAction, setUserAction] = React.useState(null);

  const [newMember, setNewMember] = React.useState(null);
  const [newMemberInputValue, setNewMemberInputValue] = React.useState('');
  const [{
    data: newMemberOptions
  }] = useAxiosWithoutCache(`/api/search/user/${newMemberInputValue}`, {
    manual: !newMemberInputValue
  });

  const [{
    data: games
  }] = useAxiosWithoutCache(`/api/profile/team/${teamId}/game`);
  const [newBotName, setNewBotName] = React.useState(null);
  const [newBotGame, setNewBotGame] = React.useState(null);

  const renderType = type ?? team?.type ?? '';
  const renderName = name ?? team?.name ?? '';
  const renderDescription = description ?? team?.description ?? '';

  if (error?.response?.status === 404) {
    return <Unknown/>;
  }
  if (!profile || !team) {
    return null;
  }
  return <>
    <Sheet>
      {!team.manager
        ? null
        : <Button color='error' variant='contained' onClick={async () => {
          if (window.confirm(`Willst du das Team "${team?.name}" wirklich unwiederruflich löschen?`)) {
            await handleAsyncError(axios.delete(`/api/profile/team/${teamId}`), enqueueSnackbar);
            navigate('/profile/teams');
          }
        }} sx={{
          float: 'right',
          ml: 1
        }}>löschen</Button>}
      <Button color='error' onClick={async () => {
        if (window.confirm(`Willst du das Team "${team?.name}" wirklich verlassen?`)) {
          await handleAsyncError(axios.post(`/api/profile/team/${teamId}/leave`), enqueueSnackbar);
          navigate('/profile/teams');
        }
      }} sx={{
        float: 'right',
        ml: 1
      }}>verlassen</Button>
      <Typography variant='h4' gutterBottom sx={{flexGrow: 1, flexShrink: 1}}>{team?.name}</Typography>
      <Stack spacing={2}>
        {!team.manager
          ? <Typography variant='body1' color='text.secondary' gutterBottom>{team?.description}</Typography>
          : <>
            <TextField size='small' type='text' label='Name'
              value={renderName} onChange={(event) => {
                setName(event.target.value);
              }}/>
            <FormControl>
              <FormLabel>Einstellung für neue Mitglieder</FormLabel>
              <RadioGroup
                value={renderType}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              >
                <FormControlLabel value='PRIVATE' control={<Radio/>} label='Beitrittsanfragen nicht erlauben'/>
                <FormControlLabel value='PUBLIC' control={<Radio/>} label='Beitrittsanfragen erlauben'/>
                <FormControlLabel value='AUTOACCEPT' control={<Radio/>} label='Jeder darf beitreten'/>
              </RadioGroup>
            </FormControl>
            <TextField size='small' type='text' label='Beschreibung' multiline rows={5}
              value={renderDescription} onChange={(event) => {
                setDescription(event.target.value);
              }}/>
            <Box sx={{
              display: 'flex',
              gridAutoFlow: 'column',
              gridGap: theme.spacing(2)
            }}>
              <Box sx={{flexGrow: 1, flexShrink: 1}}/>
              <Button variant='contained' disabled={!renderName} onClick={async () => {
                await handleAsyncError(axios.post(`/api/profile/team/${teamId}`, {
                  type: renderType,
                  name: renderName,
                  description: renderDescription
                }), enqueueSnackbar);
                reload();
              }}>speichern</Button>
            </Box>
          </>}
      </Stack>
      <Table size='small' sx={{mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})`}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{pl: 1, pr: 1}} colSpan={2}>Spieler</TableCell>
            <TableCell sx={{pl: 1, pr: 1, width: 0}} colSpan={!team.manager ? 1 : 2}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team?.members?.map((user) => {
            let statusAction = null;
            const statusText = [];
            if (user.manager) {
              statusText.push('Manager');
            }
            if (!user.accepted_account) {
              statusText.push('eingeladen');
            } else if (!user.accepted_team) {
              if (team.manager) {
                statusAction = <Button variant='outlined' color='success' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/team/${teamId}/accept/${user.id}`, {}), enqueueSnackbar);
                  reload();
                }}>annehmen</Button>;
              } else {
                statusText.push('angefragt');
              }
            }
            return <TableRow
              key={user.id}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell sx={{pl: 1, pr: 1, width: 0}}>
                <UserAvatar key={user.id} profile={user}/>
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}} component='th' scope='row'>
                {user.username}
              </TableCell>
              <TableCell sx={{
                pl: 1,
                pr: 1,
                width: 0
              }}>{statusAction ?? statusText.join(', ')}</TableCell>
              {!team.manager
                ? null
                : <TableCell sx={{pl: 1, pr: 1, width: 0}}>
                  <IconButton onClick={(event) => {
                    setUserAction({
                      id: user.id,
                      element: event.target
                    });
                  }}>
                    <ExpandCircleDownIcon/>
                  </IconButton>
                  <Menu
                    anchorEl={userAction?.element}
                    open={userAction?.id === user.id}
                    onClose={() => setUserAction(null)}
                  >
                    {user.manager
                      ? <MenuItem onClick={async () => {
                        if (user.id !== profile.id || window.confirm('Willst du dich wirklich selbst degradieren?')) {
                          await handleAsyncError(axios.post(`/api/profile/team/${teamId}/downgrade/${user.id}`, {}), enqueueSnackbar);
                          reload();
                          setUserAction(null);
                        }
                      }}>degradieren</MenuItem>
                      : <MenuItem onClick={async () => {
                        await handleAsyncError(axios.post(`/api/profile/team/${teamId}/upgrade/${user.id}`, {}), enqueueSnackbar);
                        reload();
                        setUserAction(null);
                      }}>befördern</MenuItem>}
                    {user.id === profile.id
                      ? null
                      : <MenuItem onClick={async () => {
                        if (window.confirm(`Willst du "${user?.username}" wirklich aus dem Team entfernen?`)) {
                          await handleAsyncError(axios.post(`/api/profile/team/${teamId}/remove/${user.id}`, {}), enqueueSnackbar);
                          reload();
                          setUserAction(null);
                        }
                      }}>entfernen</MenuItem>}
                  </Menu>
                </TableCell>}
            </TableRow>;
          })}
          {!team.manager
            ? null
            : <TableRow>
              <TableCell sx={{pl: 1, pr: 1}} colSpan={2}>
                <Autocomplete
                  getOptionLabel={(option) => option?.username}
                  filterOptions={(x) => x}
                  options={newMemberOptions ?? []}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={newMember}
                  onChange={(event: any, newValue) => {
                    setNewMember(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setNewMemberInputValue(newInputValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} size='small' label='Mitspieler suchen' fullWidth/>
                    );
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems='center' gap={2}>
                          <Grid item>
                            <UserAvatar profile={option}/>
                          </Grid>
                          <Grid item>
                            {option?.username}
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  sx={{flexGrow: 1, flexShrink: 1}}
                />
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, width: 0}} colSpan={2}>
                <Button variant='contained' fullWidth disabled={!newMember} onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/team/${teamId}/invite/${newMember?.id}`, {}), enqueueSnackbar);
                  setNewMember(null);
                  setNewMemberInputValue('');
                  reload();
                }}>einladen</Button>
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
      <Table size='small' sx={{mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})`}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{pl: 1, pr: 1}}>Bot</TableCell>
            <TableCell sx={{pl: 1, pr: 1}}>Spiel</TableCell>
            <TableCell sx={{pl: 1, pr: 1}}>Secret</TableCell>
            <TableCell sx={{pl: 1, pr: 1}}>Status</TableCell>
            {team.manager ? <TableCell sx={{pl: 1, pr: 1, width: 0}}/> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {team?.bots?.map((bot) => {
            return <TableRow
              key={bot.id}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}} component='th' scope='row'>
                {bot.name}
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}}>
                {bot.game}
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}}>
                {bot.secret}
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}} component='th' scope='row'>
                <Tooltip placement='left' title={`letztes Spiel: ${bot.last_game}`}>
                  {bot.online
                    ? <Chip size='small' color='success' label='online' variant='outlined'/>
                    : <Chip size='small' color='error' label='offline' variant='outlined'/>}
                </Tooltip>
              </TableCell>
              {!team.manager
                ? null
                : <TableCell sx={{pl: 1, pr: 1, width: 0, textAlign: 'right'}}>
                  <IconButton onClick={(event) => {
                    setUserAction({
                      id: bot.id,
                      element: event.target
                    });
                  }}>
                    <ExpandCircleDownIcon/>
                  </IconButton>
                  <Menu
                    anchorEl={userAction?.element}
                    open={userAction?.id === bot.id}
                    onClose={() => setUserAction(null)}
                  >
                    <MenuItem onClick={async () => {
                      await handleAsyncError(axios.post(`/api/profile/team/${teamId}/regenerate/${bot.id}`, {}), enqueueSnackbar);
                      reload();
                      setUserAction(null);
                    }}>Secret regenerieren</MenuItem>
                    <MenuItem onClick={async () => {
                      await handleAsyncError(axios.post(`/api/profile/team/${teamId}/delete/${bot.id}`, {}), enqueueSnackbar);
                      reload();
                      setUserAction(null);
                    }}>entfernen</MenuItem>
                  </Menu>
                </TableCell>}
            </TableRow>;
          })}
          {!team.manager
            ? null
            : <TableRow>
              <TableCell sx={{pl: 1, pr: 1}}>
                <TextField size='small' fullWidth type='text' label='Name'
                  value={newBotName || ''} onChange={(event) => {
                    setNewBotName(event.target.value);
                  }}/>
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1}}>
                <FormControl size='small' fullWidth>
                  <InputLabel id='game-select-label'>Spiel</InputLabel>
                  <Select labelId='game-select-label'
                    value={newBotGame || ''}
                    onChange={(event) => {
                      setNewBotGame(event.target.value);
                    }}>
                    {(games ?? []).map((game) => {
                      return <MenuItem key={game?.id} value={game?.id}>
                        {game?.name} - {game?.subtitle}
                      </MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, width: 0}} colSpan={3}>
                <Button variant='contained' fullWidth disabled={!(newBotGame && newBotName)} onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/team/${teamId}/bot`, {
                    game: newBotGame,
                    name: newBotName
                  }), enqueueSnackbar);
                  setNewBotName(null);
                  setNewBotGame(null);
                  reload();
                }}>anlegen</Button>
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </Sheet>
  </>;
};
export default React.memo(ProfileTeam);
