import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
// import {connect} from 'socket.io-client';
import chromaJs from 'chroma-js';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {BrowserRouter} from 'react-router-dom';
import {faDiscord, faGithub} from '@fortawesome/free-brands-svg-icons';
import {makeUseAxios} from 'axios-hooks';

import './robots.txt';
import './manifest.webmanifest';
import './browserconfig.xml';

import './images/android-icon-144x144.png';
import './images/android-icon-192x192.png';
import './images/android-icon-36x36.png';
import './images/android-icon-48x48.png';
import './images/android-icon-72x72.png';
import './images/android-icon-96x96.png';
import './images/apple-icon-114x114.png';
import './images/apple-icon-120x120.png';
import './images/apple-icon-144x144.png';
import './images/apple-icon-152x152.png';
import './images/apple-icon-180x180.png';
import './images/apple-icon-57x57.png';
import './images/apple-icon-60x60.png';
import './images/apple-icon-72x72.png';
import './images/apple-icon-76x76.png';
import './images/apple-icon-precomposed.png';
import './images/apple-icon.png';
import './images/favicon-16x16.png';
import './images/favicon-32x32.png';
import './images/favicon-96x96.png';
import './images/favicon.ico';
import './images/icon.png';
import './images/logo.png';
import './images/ms-icon-144x144.png';
import './images/ms-icon-150x150.png';
import './images/ms-icon-310x310.png';
import './images/ms-icon-70x70.png';

import App from './components/App';

export const chroma = chromaJs;

// const io = connect({
//   withCredentials: true,
//   transports: ['websocket']
// });
// io.on('connect', () => {
//   console.log('connected');
// });
// io.on('disconnect', () => {
//   console.log('disconnected');
// });

export const languages = {
  en: 'English',
  de: 'Deutsch',
  cimode: 'Debug'
};

export const logins = [
  {
    id: 'discord',
    title: 'Discord',
    disabled: false,
    icon: faDiscord
  },
  {
    id: 'github',
    title: 'GitHub',
    disabled: false,
    icon: faGithub
  }/* ,
  {
    id: 'google',
    title: 'Google',
    disabled: false,
    icon: faGoogle
  },
  {
    id: 'facebook',
    title: 'Facebook',
    disabled: false,
    icon: faFacebookF
  } */
];

export const useAxiosWithoutCache = makeUseAxios({
  cache: false
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('../locales/en/translation.json')
      },
      de: {
        translation: require('../locales/de/translation.json')
      }
    },
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false
    }
  })
  .then(() => {
    const root = ReactDOMClient.createRoot(document.getElementById('root'));
    root.render(
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    );
  });
