import * as React from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, Box, Button, Typography, useTheme} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {useAxiosWithoutCache} from '../index';
import {handleAsyncError} from '../util/handleAsyncError';

import Sheet from './Sheet';
import Unknown from './Unknown';
import {ProfileContext} from './App';

const Competition = (props) => {
  const {competitionId} = useParams();
  const theme = useTheme();
  const {t} = useTranslation();
  const {profile, profileReload} = useContext(ProfileContext);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [{
    data: competition,
    loading,
    error
  }, reload] = useAxiosWithoutCache(`/api/competition/${competitionId}`);

  if (error?.response?.status === 404) {
    return <Unknown/>;
  }
  if (!competition) {
    return null;
  }
  const subtitle = [];
  switch (competition.type) {
    case 'PRIVATE':
      subtitle.push('privat');
      break;
    case 'PUBLIC':
      subtitle.push('mit Anmeldung');
      break;
    case 'AUTOACCEPT':
      subtitle.push('öffentlich');
      break;
  }
  const dateStringOptions: any = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  };
  const start = !competition.start ? null : new Date(competition.start);
  const end = !competition.end ? null : new Date(competition.end);
  const start_registration = !competition.start_registration ? null : new Date(competition.start_registration);
  const end_registration = !competition.end_registration ? null : new Date(competition.end_registration);
  if (start && end) {
    subtitle.push(`${start.toLocaleDateString('de-DE', dateStringOptions)} bis ${end.toLocaleDateString('de-DE', dateStringOptions)}`);
  } else if (start) {
    subtitle.push(`ab ${start.toLocaleDateString('de-DE', dateStringOptions)}`);
  } else if (end) {
    subtitle.push(`bis ${end.toLocaleDateString('de-DE', dateStringOptions)}`);
  } else {
    subtitle.push('unbegrenzt');
  }
  if (start_registration && end_registration) {
    subtitle.push(`Registrierung ${start_registration.toLocaleDateString('de-DE', dateStringOptions)} bis ${end_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
  } else if (start_registration) {
    subtitle.push(`Registrierung ab ${start_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
  } else if (end_registration) {
    subtitle.push(`Registrierung bis ${end_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
  }
  let action;
  if (profile) {
    if (competition.accepted_account && competition.accepted_competition) {
      action = <Alert>beigetreten</Alert>;
    } else {
      switch (competition.type) {
        case 'PRIVATE':
          action = <Alert severity='info'>private Veranstaltung</Alert>;
          break;
        case 'PUBLIC':
          if (competition.accepted_account && !competition.accepted_competition) {
            action = <Alert severity='info'>Einladung angefragt</Alert>;
          } else if (!competition.accepted_account && competition.accepted_competition) {
            action = <Button variant='contained' onClick={async () => {
              await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/accept`), enqueueSnackbar);
              reload();
              profileReload();
            }}>Einladung annehmen</Button>;
          } else {
            action = <Button variant='contained' onClick={async () => {
              await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/join`), enqueueSnackbar);
              reload();
              profileReload();
            }}>Einladung anfragen</Button>;
          }
          break;
        case 'AUTOACCEPT':
          action = <Button variant='contained' onClick={async () => {
            await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/join`), enqueueSnackbar);
            reload();
            profileReload();
          }}>beitreten</Button>;
          break;
      }
    }
  }
  return <Sheet>
    <Box sx={{
      float: 'right',
      ml: 1
    }}>{action}</Box>
    <Typography variant='h4'>{competition.name}</Typography>
    <Typography gutterBottom variant='subtitle1'>{subtitle.join(', ')}</Typography>
    <Typography variant='body1' color='text.secondary'>{competition.description}</Typography>
  </Sheet>;
};
export default React.memo(Competition);
