export const handleAsyncError = async (promise, errorMessageHandler = null) => {
  try {
    const result = await promise;
    return result?.data ?? result;
  } catch (e) {
    let message = e.message;
    console.log('e', e.isAxiosError, {...e});
    if (e.isAxiosError) {
      message = e.response?.data ?? e.response?.statusText ?? e.message;
    }
    errorMessageHandler?.(message, {
      variant: 'error'
    });
    throw e;
  }
};
