import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Link, Paper, Typography, useTheme} from '@mui/material';

const Footer = (props) => {
  const theme = useTheme();
  const {
    t,
    i18n
  } = useTranslation();

  return <Paper sx={{background: 'white', pt: 3, pb: 3, mt: 2}} square elevation={4}>
    <Container sx={{textAlign: 'center'}}>
      {/* <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        mb: 1
      }}>
        <Typography variant='body2' sx={{
          mx: 1,
          color: 'text.secondary',
          fontWeight: 'bold'
        }}>{t('footer.language')}:</Typography>
        {Object.entries(languages).map(([lang, title]) =>
          <Link key={lang} variant='body2' underline='none' color='inherit' href='#'
            sx={{
              mx: 1,
              color: lang === i18n.language ? 'primary.main' : 'text.secondary',
              fontWeight: lang === i18n.language ? 'bold' : undefined
            }}
            onClick={(event) => {
              event.preventDefault();
              i18n.changeLanguage(lang).then();
            }}>{title}</Link>)}
      </Box> */}
      <Typography variant='body2' color='text.secondary'>
        <Link color='text.secondary' href='https://blog.uhno.de/impressum/'
          target='impressum'>Impressum &amp; Datenschutz</Link>
      </Typography>
    </Container>
  </Paper>;
};
export default React.memo(Footer);
