import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Card, CardActionArea, CardContent, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useSnackbar} from 'notistack';
import {Link as RouterLink} from 'react-router-dom';

import {useAxiosWithoutCache} from '../index';

const Competitions = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [{
    data: competitions,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/competition');
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return <Box display='grid' gridTemplateColumns='repeat( auto-fill, minmax(320px, 1fr) )' gap={2}>
    {competitions?.map((competition) => {
      const dateStringOptions: any = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      };
      const subtitle = [];
      switch (competition.type) {
        case 'PRIVATE':
          subtitle.push('privat');
          break;
        case 'PUBLIC':
          subtitle.push('mit Anmeldung');
          break;
        case 'AUTOACCEPT':
          subtitle.push('öffentlich');
          break;
      }
      const start = !competition.start ? null : new Date(competition.start);
      const end = !competition.end ? null : new Date(competition.end);
      const start_registration = !competition.start_registration ? null : new Date(competition.start_registration);
      const end_registration = !competition.end_registration ? null : new Date(competition.end_registration);
      if (start && end) {
        subtitle.push(`${start.toLocaleDateString('de-DE', dateStringOptions)} bis ${end.toLocaleDateString('de-DE', dateStringOptions)}`);
      } else if (start) {
        subtitle.push(`ab ${start.toLocaleDateString('de-DE', dateStringOptions)}`);
      } else if (end) {
        subtitle.push(`bis ${end.toLocaleDateString('de-DE', dateStringOptions)}`);
      } else {
        subtitle.push('unbegrenzt');
      }
      if (start_registration && end_registration) {
        subtitle.push(`Registrierung ${start_registration.toLocaleDateString('de-DE', dateStringOptions)} bis ${end_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
      } else if (start_registration) {
        subtitle.push(`Registrierung ab ${start_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
      } else if (end_registration) {
        subtitle.push(`Registrierung bis ${end_registration.toLocaleDateString('de-DE', dateStringOptions)}`);
      }
      return <Card key={competition.id}>
        <CardActionArea component={RouterLink} to={`/competition/${competition.id}`} sx={{height: '100%'}}>
          <CardContent sx={{p: 3}}>
            <Typography variant='h5' component='div'>
              {competition.name}
            </Typography>
            <Typography gutterBottom variant='subtitle1' component='div'>
              {subtitle.join(', ')}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {competition.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>;
    })}
  </Box>;
};
export default React.memo(Competitions);
