import * as React from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, Box, Button, Typography, useTheme} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {useAxiosWithoutCache} from '../index';
import {handleAsyncError} from '../util/handleAsyncError';

import Sheet from './Sheet';
import Unknown from './Unknown';
import {ProfileContext} from './App';

const Team = (props) => {
  const {teamId} = useParams();
  const theme = useTheme();
  const {t} = useTranslation();
  const {profile, profileReload} = useContext(ProfileContext);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [{
    data: team,
    loading,
    error
  }, reload] = useAxiosWithoutCache(`/api/team/${teamId}`);

  if (error?.response?.status === 404) {
    return <Unknown/>;
  }
  if (!team) {
    return null;
  }
  const subtitle = [];
  switch (team.type) {
    case 'PRIVATE':
      subtitle.push('privat');
      break;
    case 'PUBLIC':
      subtitle.push('mit Anmeldung');
      break;
    case 'AUTOACCEPT':
      subtitle.push('öffentlich');
      break;
  }
  let action;
  if (profile) {
    if (team.accepted_account && team.accepted_team) {
      action = <Alert>beigetreten</Alert>;
    } else {
      switch (team.type) {
        case 'PRIVATE':
          action = <Alert severity='info'>privates Team</Alert>;
          break;
        case 'PUBLIC':
          if (team.accepted_account && !team.accepted_team) {
            action = <Alert severity='info'>Einladung angefragt</Alert>;
          } else if (!team.accepted_account && team.accepted_team) {
            action = <Button variant='contained' onClick={async () => {
              await handleAsyncError(axios.post(`/api/profile/team/${team.id}/accept`), enqueueSnackbar);
              reload();
              profileReload();
            }}>Einladung annehmen</Button>;
          } else {
            action = <Button variant='contained' onClick={async () => {
              await handleAsyncError(axios.post(`/api/profile/team/${team.id}/join`), enqueueSnackbar);
              reload();
              profileReload();
            }}>Einladung anfragen</Button>;
          }
          break;
        case 'AUTOACCEPT':
          action = <Button variant='contained' onClick={async () => {
            await handleAsyncError(axios.post(`/api/profile/team/${team.id}/join`), enqueueSnackbar);
            reload();
            profileReload();
          }}>beitreten</Button>;
          break;
      }
    }
  }
  return <Sheet>
    <Box sx={{
      float: 'right',
      ml: 1
    }}>{action}</Box>
    <Typography variant='h4'>{team.name}</Typography>
    <Typography gutterBottom variant='subtitle1'>{subtitle.join(', ')}</Typography>
    <Typography variant='body1' color='text.secondary'>{team.description}</Typography>
  </Sheet>;
};
export default React.memo(Team);
