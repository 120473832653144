import * as React from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, Typography} from '@mui/material';

import {ProfileContext} from './App';
import Sheet from './Sheet';
import Games from './Games';
import Competitions from './Competitions';

const Start = (props) => {
  const {t} = useTranslation();
  const {profile} = useContext(ProfileContext);

  return <>
    <Sheet>
      <Typography gutterBottom variant='h5'>
        Was soll das?
      </Typography>
      <Typography gutterBottom variant='body1'>
        Nachdem wir mehrfach den <Link href='https://www.hackathon-leer.de/'>Hackathon Leer</Link> organisiert haben und
        immer wieder Fragen danach aufkamen, ob die dabei entstandenen Aufgaben/Spiele nicht durchgehend online
        verfügbar sein könnten, ist es jetzt endlich so weit.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Alle Spiele funktionieren, indem die Bots der Spieler per <Link href='https://github.com/socketio/socket.io-protocol'>Socket.IO-Protokoll 5</Link> (also Socket.IO-Version 4.x) mit unserem Server kommunizieren.
        Client-Libraries dafür gibt es für viele Programmiersprachen, z.B.
        <ul>
          <li><Link href='https://github.com/socketio/socket.io-client'>JavaScript</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-javascript-nodejs-ug'>Schnick, Schnack, Schnuck von Udo</Link>)</li>
          <li><Link href='https://github.com/socketio/socket.io-client-java'>Java</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-java-maven-bb'>Schnick, Schnack, Schnuck von Bobo</Link>)</li>
          <li><Link href='https://python-socketio.readthedocs.io/en/latest/client.html'>Python</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-python-cb'>Schnick, Schnack, Schnuck von Carsten</Link>)</li>
          <li><Link href='https://github.com/socketio/socket.io-client-cpp'>C++</Link></li>
          <li><Link href='https://github.com/doghappy/socket.io-client-csharp'>C#</Link></li>
          <li><Link href='https://docs.rs/rust_socketio/latest/rust_socketio/'>Rust</Link></li>
          <li><Link href='https://github.com/ElephantIO/elephant.io'>PHP</Link></li>
          <li>...</li>
        </ul>
      </Typography>
      <Typography variant='body1'>
        Also: melde dich an, gründe ein Team und leg los!
      </Typography>
    </Sheet>
    <Typography gutterBottom variant='h4' sx={{mt: 3}}>
      Verfügbare Spiele
    </Typography>
    <Games/>
    <Typography gutterBottom variant='h4' sx={{mt: 3}}>
      Anstehende Turniere
    </Typography>
    <Competitions/>
  </>;
};
export default React.memo(Start);
