import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import md5 from 'md5';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';

import {logins} from '..';
import {handleAsyncError} from '../util/handleAsyncError';

import {ProfileContext} from './App';

const Profile = ({onClose}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {
    profile,
    profileLoading,
    profileReload
  } = useContext(ProfileContext);
  const [username, setUsername] = useState(null);
  const [photo, setPhoto] = useState(null);

  if (profileLoading) {
    return null;
  } else if (profile) {
    return <>
      <DialogTitle>Profil</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {profile.competitioninvites?.map((competition) => {
            return <Alert key={competition.id} severity='info' variant='outlined' action={
              <Stack>
                <Button color='success' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/accept`), enqueueSnackbar);
                  profileReload();
                }}>
                  annehmen
                </Button>
                <Button color='error' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/leave`), enqueueSnackbar);
                  profileReload();
                }}>
                  ablehnen
                </Button>
              </Stack>
            }>
              <AlertTitle>Turnier-Einladung</AlertTitle>
              Du wurdest eingeladen, Turnier <strong>{competition.name}</strong> beizutreten.
            </Alert>;
          })}
          {profile.invites?.map((team) => {
            return <Alert key={team.id} severity='info' variant='outlined' action={
              <Stack>
                <Button color='success' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/team/${team.id}/accept`), enqueueSnackbar);
                  profileReload();
                }}>
                  annehmen
                </Button>
                <Button color='error' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/team/${team.id}/leave`), enqueueSnackbar);
                  profileReload();
                }}>
                  ablehnen
                </Button>
              </Stack>
            }>
              <AlertTitle>Team-Einladung</AlertTitle>
              Du wurdest eingeladen, Team <strong>{team.name}</strong> beizutreten.
            </Alert>;
          })}
          <Box sx={{
            display: 'flex',
            gridAutoFlow: 'column',
            gridGap: theme.spacing(2)
          }}>
            <TextField type='text' sx={{flexGrow: 1, flexShrink: 1}} label='Benutzername'
              value={username ?? profile.username} onChange={(event) => {
                setUsername(event.target.value);
              }}/>
            <FormControl>
              <InputLabel id='avatar-select-label'>Avatar</InputLabel>
              <Select size='small' sx={{width: '10ch'}} labelId='avatar-select-label' label='Avatar'
                value={photo ?? profile.photo ?? `https://vanillicon.com/v2/${md5(profile.id)}.svg`}
                onChange={(event) => {
                  setPhoto(event.target.value);
                }}>
                {logins.map(({id}) => {
                  const loginProfile = profile.logins.find((l) => l.id === id);
                  if (loginProfile?.photo) {
                    return <MenuItem key={id} value={loginProfile.photo}>
                      <Avatar src={loginProfile.photo}/>
                    </MenuItem>;
                  }
                  return null;
                })}
                <MenuItem value={`https://vanillicon.com/v2/${md5(profile.id)}.svg`}>
                  <Avatar src={`https://vanillicon.com/v2/${md5(profile.id)}.svg`}/>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {logins.map(({
            id,
            title,
            icon,
            disabled
          }) => {
            const loginProfile = profile.logins.find((l) => l.id === id);
            if (loginProfile) {
              return <Box key={id} sx={{
                display: 'flex',
                gridAutoFlow: 'column',
                gridGap: theme.spacing(2),
                alignItems: 'center',
                color: `${id}.main`
              }}>
                <FontAwesomeIcon icon={icon} fixedWidth/>
                <Box sx={{flexGrow: 1, flexShrink: 1}}>{title}</Box>
                <Box>{loginProfile.username}</Box>
                <Avatar src={loginProfile.photo} alt={loginProfile.username} sx={{
                  bgcolor: `${id}.main`,
                  color: `${id}.contrastText`,
                  float: 'right'
                }}/>
              </Box>;
            }
            return <Box key={id} sx={{
              display: 'flex',
              gridAutoFlow: 'column',
              gridGap: theme.spacing(2),
              alignItems: 'center',
              color: `${id}.main`
            }}>
              <FontAwesomeIcon icon={icon} fixedWidth/>
              <Box sx={{flexGrow: 1, flexShrink: 1}}>{title}</Box>
              <Button variant='contained' disabled={disabled} color={id as any}
                href={`/auth/${id}/login`}
                startIcon={<FontAwesomeIcon icon={icon}/>} sx={{
                  float: 'right'
                }}>verknüpfen</Button>
            </Box>;
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>schließen</Button>
        <Button onClick={async () => {
          await handleAsyncError(axios.post('/api/profile', {
            username: username ?? profile.username,
            photo: photo ?? profile.photo
          }), enqueueSnackbar);
          profileReload();
          onClose?.();
        }}>speichern</Button>
      </DialogActions>
    </>;
  }
  return <>
    <DialogTitle>Anmelden via...</DialogTitle>
    <DialogContent dividers>
      <Stack spacing={2}>
        {logins.map(({
          id,
          title,
          icon,
          disabled
        }) =>
          <Button key={id} variant='contained' disabled={disabled} color={id as any}
            href={`/auth/${id}/login`}
            startIcon={<FontAwesomeIcon icon={icon}/>}>{title}</Button>)}
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button color='error' onClick={onClose}>schließen</Button>
    </DialogActions>
  </>;
};
export default React.memo(Profile);
