import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, useTheme} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import MuiMarkdown from 'mui-markdown';
import vsLight from 'prism-react-renderer/themes/vsLight';
console.log('vsLight',vsLight);

import {useAxiosWithoutCache} from '../index';

import Sheet from './Sheet';
import Unknown from './Unknown';

const Game = (props) => {
  const {gameId} = useParams();
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [{
    data: game,
    loading,
    error
  }, reload] = useAxiosWithoutCache(`/api/game/${gameId}`);

  if (error?.response?.status === 404) {
    return <Unknown/>;
  }
  if (!game) {
    return null;
  }
  return <Sheet>
    <Typography variant='h4'>{game.name}</Typography>
    <Typography gutterBottom variant='subtitle1'>{game.subtitle}</Typography>
    <Typography gutterBottom variant='body1' color='text.secondary'>{game.description}</Typography>
    <MuiMarkdown codeBlockTheme={vsLight}>{game.getstarted}</MuiMarkdown>
  </Sheet>;
};
export default React.memo(Game);
