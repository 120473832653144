import {useSearchParams} from 'react-router-dom';

export const useSearchParamsState = (
  searchParamName: string,
  defaultValue: string
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState: string) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState: string) => {
    const next = Object.assign(
      {},
      [...searchParams.entries()].filter(([key]) => key !== searchParamName).reduce(
        (o, [key, value]) => ({
          ...o,
          [key]: value
        }),
        {}
      ),
      newState == null ? {} : {[searchParamName]: newState}
    );
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
};
