import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, LinearProgress, Stack, Typography, useTheme} from '@mui/material';
import axios from 'axios';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import {useAxiosWithoutCache} from '../index';
import {useSearchParamsState} from '../util/useSearchParamsState';

import Sheet from './Sheet';

const check = (board) => {
  for (const symbol of ['X', 'O']) {
    if (board[0] === symbol && board[1] === symbol && board[2] === symbol) {
      return [0, 1, 2];
    }
    if (board[3] === symbol && board[4] === symbol && board[5] === symbol) {
      return [3, 4, 5];
    }
    if (board[6] === symbol && board[7] === symbol && board[8] === symbol) {
      return [6, 7, 8];
    }
    if (board[0] === symbol && board[3] === symbol && board[6] === symbol) {
      return [0, 3, 6];
    }
    if (board[1] === symbol && board[4] === symbol && board[7] === symbol) {
      return [1, 4, 7];
    }
    if (board[2] === symbol && board[5] === symbol && board[8] === symbol) {
      return [2, 5, 8];
    }
    if (board[0] === symbol && board[4] === symbol && board[8] === symbol) {
      return [0, 4, 8];
    }
    if (board[2] === symbol && board[4] === symbol && board[6] === symbol) {
      return [2, 4, 6];
    }
  }
  return [];
};

const DisplayMTTT = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [{
    data: info,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/display?game=MTTT');
  const [competition, setCompetition] = useSearchParamsState('competition', null);
  const [{
    data: ranking
  }, reloadRanking] = useAxiosWithoutCache({
    url: '/api/ranking/bot',
    params: {competition, game: 'MTTT'}
  });
  const [bots, setBots] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadRanking();
    }, 10000);
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    setStep(0);
    setBots(null);
    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(async (id) => (await axios.get(`/api/bot/${id}`)).data));
        setBots(bots);
      })();
    }
  }, [info]);

  useEffect(() => {
    let timeout;
    if (!info || !bots) {
      console.log('still loading');
    } else {
      // setStep(info.data.states.length);
      if (step === info.data.states.length) {
        timeout = setTimeout(() => {
          reload();
        }, 5000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 2000);
      }
    }
    return () => clearTimeout(timeout);
  }, [step, info, bots, reload]);

  const state = info?.data?.states?.[step - 1];

  const renderBoard = (board, overview, active) => {
    const overviewMap = check(overview);
    return <Stack direction='row' sx={{
      transition: 'all 0.25s',
      border: 'min(0.5vw,0.75vh) solid transparent',
      margin: 'min(0.5vw,0.75vh)',
      borderRadius: 'min(1vw,1.5vh)',
      display: 'grid',
      gridTemplateRows: 'auto auto auto',
      gridTemplateColumns: 'auto auto auto',
      overflow: 'hidden',
      position: 'relative'
    }}>
      {board.map((fields, section) => {
        const sectionMap = check(fields);
        const completed = overview[section] !== '';
        return <Stack direction='column' key={section} sx={{
          transition: 'all 0.5s',
          transitionDelay: '1s',
          display: 'grid',
          gridTemplateRows: 'auto auto auto',
          gridTemplateColumns: 'auto auto auto',
          border: `min(0.25vw,0.375vh) solid ${theme.palette.primary.main}`,
          borderTop: Math.floor(section / 3) === 0 ? '0px solid transparent' : null,
          borderBottom: Math.floor(section / 3) === 2 ? '0px solid transparent' : null,
          borderLeft: section % 3 === 0 ? '0px solid transparent' : null,
          borderRight: section % 3 === 2 ? '0px solid transparent' : null,
          background: step !== info.data.states.length && ((state.forcedSection != null && state.forcedSection !== section) || completed) ? '#95a5a6' : null,
          position: 'relative'
        }}>
          {completed
            ? <Box sx={{
              zIndex: 10,
              filter: `grayscale(${overviewMap.length && !overviewMap.includes(section) ? 1 : 0})`,
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              fontSize: 'min(9vw,13.5vh)',
              textAlign: 'center',
              lineHeight: 'min(15vw,22.5vh)'
            }}>
              {overview[section] === 'X' ? '✖️' : (overview[section] === 'O' ? '⭕' : '💩')}
            </Box>
            : null}
          {fields.map((value, field) => {
            let content = '';
            const style: any = {};
            switch (value) {
              case 'X':
                style.fontSize = 'min(3vw,4.5vh)';
                content = '✖️';
                break;
              case 'O':
                style.fontSize = 'min(3vw,4.5vh)';
                content = '⭕';
                break;
            }
            // style.fontSize = 'min(3vw,4.5vh)';
            // content = hitContent;
            if (section === state.move?.[0] && field === state.move?.[1]) {
              // style.boxShadow = 'inset 0 0 0 0.5px white, inset 0 0 0 min(0.5vw,0.75vh) #c0392b';
              // style.borderRadius = '50%';
              // style.zIndex = 10;
              style.fontSize = 'min(3vw,4.5vh)';
              // style.outline = `min(0.5vw,0.75vh) solid ${theme.palette.primary.main}`;
              // style.zIndex = 10;
              // style.boxShadow = `inset 0 0 0 min(0.5vw,0.75vh) ${theme.palette.primary.main}`;
              // if (value !== '.') {
              //   content = hitContent;
              // }
            }
            return <Box sx={{
              zIndex: 1,
              border: `min(0.125vw,0.1875vh) solid ${theme.palette.primary.main}`,
              borderTop: Math.floor(field / 3) === 0 ? '0px solid transparent' : null,
              borderBottom: Math.floor(field / 3) === 2 ? '0px solid transparent' : null,
              borderLeft: field % 3 === 0 ? '0px solid transparent' : null,
              borderRight: field % 3 === 2 ? '0px solid transparent' : null
            }} key={field}>
              <Box sx={{
                transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
                filter: `grayscale(${completed && !sectionMap.includes(field) ? 1 : 0})`,
                width: 'min(5vw,7.5vh)',
                height: 'min(5vw,7.5vh)',
                textAlign: 'center',
                lineHeight: 'min(5vw,7.5vh)',
                borderRadius: 'min(0.5vw,0.75vh)',
                fontSize: '0',
                ...style
              }} key={field}>{content}</Box>
            </Box>;
          })}
        </Stack>;
      })}
    </Stack>;
  };

  return <Sheet id='display' sx={{p: 0}} onDoubleClick={() => {
    const player = document.getElementById('display');
    if (player && document.fullscreenEnabled && player.requestFullscreen) {
      if (!document.fullscreenElement) {
        player.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }}>
    {!info || !bots || !state
      ? <LinearProgress/>
      : <>
        <Stack direction='row' justifyContent='space-evenly' sx={{height: '100%'}}>
          <Box sx={{height: '100%', width: '75%'}}>
            <Stack direction='column' justifyContent='center'
              sx={{height: 'calc(100% - min(1.5vw,2.25vh))', overflow: 'hidden'}}>
              <Stack direction='row' justifyContent='space-evenly'>
                <Box sx={{
                  width: 'min(40vw,60vh)'
                }}>
                  <Typography variant='h4'
                    color={state.active === 1 ? theme.palette.primary.main : theme.palette.text.secondary}
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(6vw,9vh)',
                      lineHeight: 'min(6vw,9vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>✖️{bots[0].name}</Typography>
                  <Typography variant='body2'
                    color={state.active === 1 ? theme.palette.primary.main : theme.palette.text.secondary}
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(2vw,3vh)',
                      lineHeight: 'min(4vw,6vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Team "{bots[0].team}"</Typography>
                </Box>
                <Box sx={{
                  width: 'min(40vw,60vh)'
                }}>
                  <Typography variant='h4' color={state.active === 0 ? '#2c3e50' : '#95a5a6'}
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(6vw,9vh)',
                      lineHeight: 'min(6vw,9vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>⭕{bots[1].name}</Typography>
                  <Typography variant='body2' color={state.active === 0 ? '#2c3e50' : '#95a5a6'}
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(2vw,3vh)',
                      lineHeight: 'min(4vw,6vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Team "{bots[1].team}"</Typography>
                </Box>
              </Stack>
              <Stack direction='row' justifyContent='space-evenly'>
                {renderBoard(state.board, state.overview, state.active)}
              </Stack>
            </Stack>
            <LinearProgress value={100 / info.data.states.length * step} variant='determinate'
              sx={{
                m: 'min(0.5vw,0.75vh)',
                height: 'min(0.5vw,0.75vh)',
                borderRadius: 'min(0.25vw,0.375vh)'
              }}/>
          </Box>
          <Stack justifyContent='center'
            sx={{height: '100%', width: '25%', overflow: 'hidden', p: 'min(1.5vw,2.25vh)', pb: 0}}>
            {ranking.slice(0, 14).map(({id, name, team, elo, online}) =>
              <Stack key={id} direction='row' justifyContent='space-between' sx={{mb: 'min(0.75vw,1.125vh)'}}>
                <Box>
                  <Typography variant='body2'
                    sx={{
                      color: '#2c3e50',
                      transition: 'all 0.5s',
                      fontSize: 'min(2vw,3vh)',
                      lineHeight: 1.25,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>{name}
                  </Typography>
                  <Typography variant='body2'
                    sx={{
                      color: '#95a5a6',
                      transition: 'all 0.5s',
                      fontSize: 'min(1vw,1.5vh)',
                      lineHeight: 1.25,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {!online &&
                      <OfflineBoltIcon sx={{verticalAlign: 'text-top', fontSize: 'inherit', color: '#e74c3c'}}/>} {team}
                  </Typography>
                </Box>
                <Typography variant='body2'
                  sx={{
                    color: '#95a5a6',
                    transition: 'all 0.5s',
                    fontSize: 'min(3vw,4.5vh)',
                    lineHeight: 1.25,
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{Math.round(elo)}</Typography>
              </Stack>)}
          </Stack>
        </Stack>
      </>}
  </Sheet>;
};
export default React.memo(DisplayMTTT);
