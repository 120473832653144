import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';

import {useAxiosWithoutCache} from '../index';
import {useSearchParamsState} from '../util/useSearchParamsState';

import UserAvatar from './UserAvatar';
import Sheet from './Sheet';

const UserRanking = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [{
    data: competitions
  }] = useAxiosWithoutCache('/api/competition');
  const [competition, setCompetition] = useSearchParamsState('competition', null);
  const [{
    data: games
  }] = useAxiosWithoutCache('/api/game');
  const [game, setGame] = useSearchParamsState('game', null);
  const [{
    data,
    loading,
    error
  }, reload] = useAxiosWithoutCache({
    url: '/api/ranking/user',
    params: {competition, game}
  });

  return <Sheet>
    <Typography variant='h4' gutterBottom>
      Spieler-Rangliste
    </Typography>
    <FormControl sx={{mb: 2}} size='small' fullWidth>
      <InputLabel id='competition-select-label'>Turnier</InputLabel>
      <Select labelId='competition-select-label'
        value={competition}
        onChange={(event) => {
          setCompetition(event.target.value);
        }}>
        <MenuItem value={null}>
          Gesamt
        </MenuItem>
        {(competitions ?? []).map((competition) => {
          return <MenuItem key={competition?.id} value={competition?.id}>
            {competition?.name}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
    <FormControl sx={{mb: 2}} size='small' fullWidth>
      <InputLabel id='game-select-label'>Spiel</InputLabel>
      <Select labelId='game-select-label'
        value={game ?? ''}
        onChange={(event) => {
          setGame(event.target.value);
        }}>
        <MenuItem value={null}>
          Gesamt
        </MenuItem>
        {(games ?? []).map((game) => {
          return <MenuItem key={game?.id} value={game?.id}>
            {game?.name} - {game?.subtitle}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
    <Table size='small' sx={{ml: -1, width: `calc(100% + ${theme.spacing(2)})`}}>
      <TableHead>
        <TableRow>
          <TableCell sx={{pl: 1, pr: 1}} colSpan={2}>Spieler</TableCell>
          <TableCell sx={{pl: 1, pr: 1, width: 0}} align='right'>Punkte</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((user) => (
          <TableRow
            key={user.id}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell sx={{pl: 1, pr: 1, width: 0}}>
              <UserAvatar key={user.id} profile={user}/>
            </TableCell>
            <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}} component='th' scope='row'>
              {user.username}
            </TableCell>
            <TableCell sx={{pl: 1, pr: 1, width: 0}} align='right'>{user.elo}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Sheet>;
};
export default React.memo(UserRanking);
