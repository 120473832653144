import * as React from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AvatarGroup,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Fab,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {Link as RouterLink} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import {useAxiosWithoutCache} from '../index';

import {ProfileContext} from './App';
import UserAvatar from './UserAvatar';

const ProfileTeams = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {profile, profileReload} = useContext(ProfileContext);
  const [{
    data: teams,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/profile/team');
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (!profile) {
    return null;
  }
  return <Box display='grid' gridTemplateColumns='repeat( auto-fill, minmax(320px, 1fr) )' gap={2} sx={{mt: 2}}>
    {teams?.map((team) => <Card key={team.id}>
      <CardActionArea component={RouterLink} to={`/profile/team/${team.id}`}>
        <CardContent sx={{
          height: '240px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Badge color='info' badgeContent={team.requests}>
            <Typography variant='h5' component='div'>
              {team.name}
            </Typography>
          </Badge>
          <Typography gutterBottom variant='subtitle1'>
            {team.competition}
          </Typography>
          <Typography gutterBottom variant='body2' color='text.secondary' sx={{
            height: 0,
            overflow: 'hidden',
            flexGrow: 1
          }}>
            {team.description}
          </Typography>
          <AvatarGroup max={8}>
            {team?.members?.map((member) => <UserAvatar key={member.id} profile={member} tooltip/>)}
          </AvatarGroup>
        </CardContent>
      </CardActionArea>
    </Card>)}
    {matches
      ? <Button component={RouterLink} to='/profile/team/new'>neues Team</Button>
      : <Fab color='primary' component={RouterLink} to='/profile/team/new' sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(2),
        right: (theme) => theme.spacing(2)
      }}>
        <AddIcon/>
      </Fab>}
  </Box>;
};
export default React.memo(ProfileTeams);
