import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography} from '@mui/material';

const Unknown = () => {
  const {t} = useTranslation();

  return <Box sx={{
    textAlign: 'center'
  }}>
    <Typography variant='h1'>
      404
    </Typography>
    <Typography variant='subtitle2' sx={{
      color: 'text.secondary'
    }}>
      was auch immer du suchst: hier isses nich&apos;
    </Typography>
  </Box>;
};
export default React.memo(Unknown);
