import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';

import {handleAsyncError} from '../util/handleAsyncError';
import {useAxiosWithoutCache} from '..';

import {ProfileContext} from './App';
import UserAvatar from './UserAvatar';
import Sheet from './Sheet';
import Unknown from './Unknown';

const ProfileCompetition = (props) => {
  const {competitionId} = useParams();
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {profile, profileReload} = useContext(ProfileContext);
  const [{
    data: competition,
    error
  }, reload] = useAxiosWithoutCache(`/api/profile/competition/${competitionId}`);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [userAction, setUserAction] = React.useState(null);

  const [newMember, setNewMember] = React.useState(null);
  const [newMemberInputValue, setNewMemberInputValue] = React.useState('');
  const [{
    data: newMemberOptions
  }] = useAxiosWithoutCache(`/api/search/user/${newMemberInputValue}`, {
    manual: !newMemberInputValue
  });

  const renderName = name ?? competition?.name ?? '';
  const renderDescription = description ?? competition?.description ?? '';

  if (error?.response?.status === 404) {
    return <Unknown/>;
  }
  if (!profile || !competition) {
    return null;
  }
  return <>
    <Sheet>
      {/* !competition.manager
        ? null
        : <Button color='error' variant='contained' onClick={async () => {
          if (window.confirm(`Willst du das Competition "${competition?.name}" wirklich unwiederruflich löschen?`)) {
            await handleAsyncError(axios.delete(`/api/profile/competition/${competitionId}`), enqueueSnackbar);
            navigate('/profile/competitions');
          }
        }} sx={{
          float: 'right',
          ml: 1
        }}>löschen</Button> */}
      {/* <Button color='error' onClick={async () => {
        if (window.confirm(`Willst du das Competition "${competition?.name}" wirklich verlassen?`)) {
          await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/leave`), enqueueSnackbar);
          navigate('/profile/competitions');
        }
      }} sx={{
        float: 'right',
        ml: 1
      }}>verlassen</Button> */}
      <Typography variant='h4' gutterBottom sx={{flexGrow: 1, flexShrink: 1}}>{competition?.name}</Typography>
      <Stack spacing={2}>
        {!competition.manager
          ? <Typography variant='body1' color='text.secondary' gutterBottom>{competition?.description}</Typography>
          : <>
            <TextField size='small' type='text' label='Name'
              value={renderName} onChange={(event) => {
                setName(event.target.value);
              }}/>
            <TextField size='small' type='text' label='Beschreibung' multiline rows={5}
              value={renderDescription} onChange={(event) => {
                setDescription(event.target.value);
              }}/>
            <Box sx={{
              display: 'flex',
              gridAutoFlow: 'column',
              gridGap: theme.spacing(2)
            }}>
              <Box sx={{flexGrow: 1, flexShrink: 1}}/>
              <Button variant='contained' disabled={!renderName} onClick={async () => {
                await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}`, {
                  name: renderName,
                  description: renderDescription
                }), enqueueSnackbar);
                reload();
              }}>speichern</Button>
            </Box>
          </>}
      </Stack>
      <Table size='small' sx={{mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})`}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{pl: 1, pr: 1}} colSpan={2}>Spieler</TableCell>
            <TableCell sx={{pl: 1, pr: 1, width: 0}} colSpan={!competition.manager ? 1 : 2}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {competition?.members?.map((user) => {
            let statusAction = null;
            const statusText = [];
            if (user.manager) {
              statusText.push('Manager');
            }
            if (!user.accepted_account) {
              statusText.push('eingeladen');
            } else if (!user.accepted_competition) {
              if (competition.manager) {
                statusAction = <Button variant='outlined' color='success' onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/accept/${user.id}`, {}), enqueueSnackbar);
                  reload();
                }}>annehmen</Button>;
              } else {
                statusText.push('angefragt');
              }
            }
            return <TableRow
              key={user.id}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell sx={{pl: 1, pr: 1, width: 0}}>
                <UserAvatar key={user.id} profile={user}/>
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, wordBreak: 'break-word'}} component='th' scope='row'>
                {user.username}
              </TableCell>
              <TableCell sx={{
                pl: 1,
                pr: 1,
                width: 0
              }}>{statusAction ?? statusText.join(', ')}</TableCell>
              {!competition.manager
                ? null
                : <TableCell sx={{pl: 1, pr: 1, width: 0}}>
                  <IconButton onClick={(event) => {
                    setUserAction({
                      id: user.id,
                      element: event.target
                    });
                  }}>
                    <ExpandCircleDownIcon/>
                  </IconButton>
                  <Menu
                    anchorEl={userAction?.element}
                    open={userAction?.id === user.id}
                    onClose={() => setUserAction(null)}
                  >
                    {user.manager
                      ? <MenuItem onClick={async () => {
                        if (user.id !== profile.id || window.confirm('Willst du dich wirklich selbst degradieren?')) {
                          await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/downgrade/${user.id}`, {
                          }), enqueueSnackbar);
                          reload();
                          setUserAction(null);
                        }
                      }}>degradieren</MenuItem>
                      : <MenuItem onClick={async () => {
                        await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/upgrade/${user.id}`, {
                        }), enqueueSnackbar);
                        reload();
                        setUserAction(null);
                      }}>befördern</MenuItem>}
                    {user.id === profile.id
                      ? null
                      : <MenuItem onClick={async () => {
                        if (window.confirm(`Willst du "${user?.username}" wirklich aus dem Competition entfernen?`)) {
                          await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/remove/${user.id}`, {
                          }), enqueueSnackbar);
                          reload();
                          setUserAction(null);
                        }
                      }}>entfernen</MenuItem>}
                  </Menu>
                </TableCell>}
            </TableRow>;
          })}
          {!competition.manager
            ? null
            : <TableRow>
              <TableCell sx={{pl: 1, pr: 1}} colSpan={2}>
                <Autocomplete
                  getOptionLabel={(option) => option?.username}
                  filterOptions={(x) => x}
                  options={newMemberOptions ?? []}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={newMember}
                  onChange={(event: any, newValue) => {
                    setNewMember(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setNewMemberInputValue(newInputValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} size='small' label='Mitspieler suchen' fullWidth/>
                    );
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems='center' gap={2}>
                          <Grid item>
                            <UserAvatar profile={option}/>
                          </Grid>
                          <Grid item>
                            {option?.username}
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  sx={{flexGrow: 1, flexShrink: 1}}
                />
              </TableCell>
              <TableCell sx={{pl: 1, pr: 1, width: 0}} colSpan={2}>
                <Button variant='contained' fullWidth disabled={!newMember} onClick={async () => {
                  await handleAsyncError(axios.post(`/api/profile/competition/${competitionId}/invite/${newMember?.id}`, {
                  }), enqueueSnackbar);
                  setNewMember(null);
                  setNewMemberInputValue('');
                  reload();
                }}>einladen</Button>
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </Sheet>
  </>;
};
export default React.memo(ProfileCompetition);
