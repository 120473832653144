import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, LinearProgress, Stack, Typography, useTheme} from '@mui/material';
import axios from 'axios';

import {useAxiosWithoutCache} from '../index';
import {randomChoice} from '../../bots/util/randomChoice';

import Sheet from './Sheet';

const DisplaySV = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [{
    data: info,
    loading,
    error
  }, reload] = useAxiosWithoutCache('/api/display?game=SV');
  const [bots, setBots] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    setStep(0);
    setBots(null);
    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(async (id) => (await axios.get(`/api/bot/${id}`)).data));
        setBots(bots);
      })();
    }
  }, [info]);

  useEffect(() => {
    let timeout;
    if (!info || !bots) {
      console.log('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => reload(), 3000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 1000);
      }
    }
    return () => clearTimeout(timeout);
  }, [step, info, bots, reload]);

  const state = info?.data?.states?.[step - 1];

  const renderBoard = (board, target) => {
    return <Stack direction='row' sx={{
      transition: 'all 0.25s',
      border: 'min(0.5vw,0.75vh) solid transparent',
      borderColor: target ? theme.palette.primary.main : 'transparent',
      padding: 'min(0.5vw,0.75vh)',
      borderRadius: 'min(1vw,1.5vh)'
    }}>
      {board.map((column, x) => <Stack direction='column' key={x}>
        {column.map((value, y) => {
          const seed = `${info?.id} ${x} ${y}`;
          let content = randomChoice(['🐳', '🐋', '🐬', '🐟', '🐠', '🐡', '🦈', '🐙', '🦀', '🦑'], seed);
          const hitContent = randomChoice(['💥', '🔥'], seed);
          const style: any = {};
          switch (value) {
            case '.':
              style.bgcolor = '#2980b9';
              break;
            case 'x':
              style.bgcolor = '#e74c3c';
              style.fontSize = 'min(3vw,4.5vh)';
              content = hitContent;
              break;
            case 'X':
              style.bgcolor = '#34495e';
              style.fontSize = 'min(3vw,4.5vh)';
              content = hitContent;
              break;
            case 'O':
              style.bgcolor = '#95a5a6';
              content = hitContent;
              break;
            default:
              style.bgcolor = '#3498db';
          }
          // style.fontSize = 'min(3vw,4.5vh)';
          // content = hitContent;
          if (target && x === state.move?.[0] && y === state.move?.[1]) {
            // style.boxShadow = 'inset 0 0 0 0.5px white, inset 0 0 0 min(0.5vw,0.75vh) #c0392b';
            // style.borderRadius = '50%';
            // style.zIndex = 10;
            style.fontSize = 'min(3vw,4.5vh)';
            // style.outline = `min(0.5vw,0.75vh) solid ${theme.palette.primary.main}`;
            // style.zIndex = 10;
            style.boxShadow = `inset 0 0 0 min(0.5vw,0.75vh) ${theme.palette.primary.main}`;
            // if (value !== '.') {
            //   content = hitContent;
            // }
          }
          return <Box sx={{
            transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
            width: 'min(5vw,7.5vh)',
            height: 'min(5vw,7.5vh)',
            // outline: 'min(0.5vw,0.75vh) solid transparent',
            boxShadow: 'inset 0 0 0 0.5px white',
            textAlign: 'center',
            lineHeight: 'min(5vw,7.5vh)',
            borderRadius: 'min(0.5vw,0.75vh)',
            fontSize: '0',
            ...style
          }} key={y}>{content}</Box>;
        })}
      </Stack>)}
    </Stack>;
  };

  return <Sheet id='display' sx={{p: 0}} onDoubleClick={() => {
    const player = document.getElementById('display');
    if (player && document.fullscreenEnabled && player.requestFullscreen) {
      if (!document.fullscreenElement) {
        player.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }}>
    {!info || !bots || !state
      ? <LinearProgress/>
      : <>
        <Stack direction='column' justifyContent='center' sx={{height: 'calc(100% - min(1.5vw,2.25vh))'}}>
          <Stack direction='row' justifyContent='space-evenly'>
            <Box sx={{
              width: 'min(40vw,60vh)'
            }}>
              <Typography variant='h4' color={state.active === 0 ? theme.palette.primary.main : theme.palette.text.secondary}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>{bots[0].name}</Typography>
              <Typography variant='body2' color={state.active === 0 ? theme.palette.primary.main : theme.palette.text.secondary}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(2vw,3vh)',
                  lineHeight: 'min(4vw,6vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>Team "{bots[0].team}"</Typography>
            </Box>
            <Box sx={{
              width: 'min(40vw,60vh)'
            }}>
              <Typography variant='h4' color={state.active === 1 ? '#2c3e50' : '#95a5a6'}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(6vw,9vh)',
                  lineHeight: 'min(6vw,9vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>{bots[1].name}</Typography>
              <Typography variant='body2' color={state.active === 1 ? '#2c3e50' : '#95a5a6'}
                sx={{
                  transition: 'all 0.5s',
                  fontSize: 'min(2vw,3vh)',
                  lineHeight: 'min(4vw,6vh)',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>Team "{bots[1].team}"</Typography>
            </Box>
          </Stack>
          <Stack direction='row' justifyContent='space-evenly'>
            {renderBoard(state.boards[0], state.active === 1)}
            {renderBoard(state.boards[1], state.active === 0)}
          </Stack>
        </Stack>
        <LinearProgress value={100 / info.data.states.length * step} variant='determinate'
          sx={{
            m: 'min(0.5vw,0.75vh)',
            height: 'min(0.5vw,0.75vh)',
            borderRadius: 'min(0.25vw,0.375vh)'
          }}/>
      </>}
  </Sheet>;
};
export default React.memo(DisplaySV);
